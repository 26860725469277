import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FacharbeitPage from './FacharbeitPage'


const Container = styled.div`
`

const FacharbeitListe = ({data}) => (
    <Container>
        {
            data.map((item,i) => {
                return(
                    <FacharbeitPage data={item} left={i%2==0}/>
                )
            })
        }
    </Container>
);

FacharbeitListe.propTypes = {
    data: PropTypes.object,
};

export default FacharbeitListe;
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import PdfIcon from '../icons/PDF_Icon.svg'
import DocxIcon from '../icons/DOCX_Icon.svg'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Instructor from '../icons/instructor.svg'
import Communication from '../icons/communication.svg'
import RedButton from './RedButton'
import DownloadElementWide from './DownloadElementWide'
import GeneralSlideshow from './GeneralSlideshow'

const Container = styled.div`
position:relative;
display:flex;
flex-direction:column;
justify-content:space-evenly;
align-items:center;
text-align:justify;
gap:25px;
max-width:1400px;
margin:0 auto;
padding 0 10px;

& > h2 {
    order:1;
    text-align:center;
    font-size:24px;
    font-family:Montserrat;
    margin:0;
}

font-family:Lato;
font-size:16px;

@media(min-width:601px){
    & > h2 {
        font-size:28px;
    }
}

@media(min-width:993px){
    padding:0 30px;
    gap:50px;

    & > h2 {
        display:none;
    }
    flex-direction:${props => props.left?('row'):('row-reverse')};
}
`

const TextContainer = styled.div`
position:relative;
display:flex;
flex-direction:column;
width:90%;
max-width:600px;
order:3;

& > h2 {
    display:none;
    text-align:center;
    font-size:28px;
    font-family:Montserrat;
    margin:0;
}

& > a {
    margin-top:15px;
    align-self:center;
    text-align:center;
}

@media(min-width:993px){
    & > h2 {
        display:block;
    }
    order:1;
}
`

const ImageContainer = styled.div`
position:relative;
display:flex;
flex-direction:column;
max-width:650px;
width:90%;
height:auto;
order:2;
`

const Image = styled.div`
position:relative;
width:100%;
height:auto;

& >* {
    height:100%;
    width:100%;
    max-height:450px;
}
`

const InstrContactContainer = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-evenly;
margin-top:25px;
order:2;

@media(min-width:993px){
    flex-flow:row wrap;
    margin-top:0;
}
`

const InstructorContainer = styled.div`
position:relative;
display:flex;
flex-direction:row;
align-items:center;
flex-shrink:0;
margin:10px 0;

.title{
    color:var(--tmain);
}

& p {
    margin:0 0 0 10px;
}

& > svg {
    width:75px;
    height:75px;
}
`

const ContactContainer = styled.div`
position:relative;
display:flex;
flex-direction:row;
align-items:center;
flex-shrink:0;
margin:10px 0;

.title{
    color:var(--tmain);
}

& p {
    margin:0 0 0 10px;
}

& > svg {
    width:65px;
    height:65px;
}
`

const Spacer = styled.div`
display:block;
position:relative;
height:120px;
width:100%;

&:after{
    position:absolute;
    content:'';
    top:50%;
    left:20%;
    width:60%;
    border-bottom: 1px solid var(--tgrey-light1);
}

@media(min-width:993px){
    height:250px;

    &:after{
        left:25%;
        width:50%;
    }
}
`

const FacharbeitPage = ({data,left}) => {
    return(
        <div id={data.id}>
            <Container left={left?(1):(0)}>
                <h2>{data.header}</h2>
                <TextContainer>
                    <h2>{data.header}</h2>
                    <ReactMarkdown>{data.text}</ReactMarkdown>
                    {
                        data.downloads.length>0 &&
                        data.downloads.map((item,i) => {
                            return(
                                <DownloadElementWide link={item.link}>{item.dateiname}</DownloadElementWide>
                            )
                        })
                    }
                    {
                        data.button.text!="" && <RedButton href={data.button.link}>{data.button.text}</RedButton>
                    }
                </TextContainer>
                <ImageContainer>
                    { (Array.isArray(data.imageArray))?(
                    <GeneralSlideshow slides={data.imageArray}/> 
                    ):(
                        <div>No Images found</div>
                    )
                    
                    }
                    <InstrContactContainer>
                        {
                            data.ausbilder.length>0 &&
                            <InstructorContainer>
                                <Instructor/>
                                <div>
                                    <p class="title">Ausbilder</p>
                                    {
                                        data.ausbilder.map((item,i) => (
                                            <p>{item.text}</p>
                                        ))
                                    }
                                </div>
                            </InstructorContainer>
                        }
                        {
                            data.kontakt.name!="" &&
                            <ContactContainer>
                                <Communication/>
                                <div>
                                    <p class="title">Kontakt</p>
                                    <p>{data.kontakt.name}</p>
                                    <p>{data.kontakt.email}</p>
                                </div>
                            </ContactContainer>
                        }
                    </InstrContactContainer>
                </ImageContainer>
            </Container>
            <Spacer/>
        </div>
    )
};

FacharbeitPage.propTypes = {

};

export default FacharbeitPage;
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const Container = styled.div`
position:relative;
width:90%;
max-width:1400px;
margin:-50px auto 50px auto;
display:flex;
flex-direction:column;
justify-content:space-evenly;
align-items:center;

font-family:Lato;
font-size:16px;

#title {
    text-align:center;
    font-size:24px;
    font-family:Montserrat;
    margin:0;
}

@media(min-width:993px){
    flex-direction:row;
    gap:50px;

    #title {
        font-size:28px;
    }
    
}
`

const FacharbeitKreisausbildung = ({data}) => (
    <Container id={data.id}>
        <div>
            <h2 id="title">{data.header}</h2>
            <div>
                <ReactMarkdown>{data.textL}</ReactMarkdown>
            </div>
        </div>
        <div>
            <ReactMarkdown>{data.textR}</ReactMarkdown>
        </div>
    </Container>
)

FacharbeitKreisausbildung.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string,
        header: PropTypes.string,
        textL: PropTypes.string,
        textR: PropTypes.string,
    }),
}

export default FacharbeitKreisausbildung
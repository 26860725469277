import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { FacharbeitPageTemplate } from '../templates/facharbeit-template'

const FacharbeitPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <FacharbeitPageTemplate
      list = { frontmatter.list }
      kreisausbildung = { frontmatter.kreisausbildung }
    />
  )
}

FacharbeitPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export default FacharbeitPage

export const facharbeitPageQuery = graphql`
query FacharbeitTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "facharbeit-page"}}) {
    frontmatter {
      
      list {
        id        
        header        
        text        
        downloads {
          dateiname          
          link          
        }
        button {
          text          
          link          
        }
        ausbilder {
          text          
        }
        kontakt {
          name          
          email          
        }
        imageArray {
          image
            {
            childImageSharp {
              gatsbyImageData(
                width: 800,
              )}
            }
          
        }
      }
      kreisausbildung {
        id        
        header        
        textL        
        textR        
      }
    }
  }
}
`
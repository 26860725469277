import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import FacharbeitListe from '../components/FacharbeitListe'
import FacharbeitKreisausbildung from '../components/FacharbeitKreisausbildung'
import Spacer from '../components/Spacer'

export const FacharbeitPageTemplate = ({
  list,
  kreisausbildung,
}) => (
    <div>
      <Helmet title="Facharbeit" defer={false}/>
      <Layout activePageIndex={3}>
        <Spacer height={100}/>
        <FacharbeitListe data={list}/>
        <FacharbeitKreisausbildung data={kreisausbildung}/>
        <Spacer height={100}/>
      </Layout>
    </div>
)

FacharbeitPageTemplate.propTypes = {
  list: PropTypes.array,
  kreisausbildung: PropTypes.object,
}